import React, { useEffect, useState, useMemo } from "react";
import { graphql, navigate } from "gatsby";
import { Layout } from "../components/Layout";
import { Pagination } from "../components/Pagination";
import { Section } from "../components/Section";
import { SocialMediaLinksFooter } from "../components/SocialMediaLinksFooter";
import styled from "styled-components";
import { SEO, SubPagesGrid } from "../components";
import { render } from "datocms-structured-text-to-plain-text";
import qs from "query-string";
import { Helmet } from "react-helmet";
import axios from "axios";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  > div {
    padding: 20px;
  }
  h2 {
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const FilterBar = styled.div`
  max-width: 1140px;
  border-radius: 5px;
  padding: 20px 30px;
  box-sizing: border-box;
  margin: -10px auto 0 auto;
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  input,
  select {
    padding: 8px 16px;
    width: 100%;
    font-size: 16px;
    height: 45px;
  }
  select {
    color: ${(p) => p.theme.mutedColor} !important;
    font-family: ${(p) => p.theme.bodyFontFamily};
  }
  input {
    &::placeholder {
      color: ${(p) => p.theme.mutedColor} !important;
    }
  }
  @media (max-width: 767px) {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    > form {
      width: 100%;
    }
    > div {
      flex-grow: 1;
      flex-basis: 0;
    }
  }
  @media (max-width: 575px) {
    display: block;
    > div {
      margin-top: 20px;
    }
  }
`;

const Blog = ({ data, location, pageContext }) => {
  const { s, c, p, t } = qs.parse(location.search);
  const [searchTerm, setSearchTerm] = useState(s || "");
  const [totalPages, setTotalPages] = useState(0);
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    c || typeof window === "undefined" ? null : ""
  );
  const [selectedTag, setSelectedTag] = useState(
    c || typeof window === "undefined" ? null : ""
  );
  const currentPage = isNaN(parseInt(p)) ? 1 : parseInt(p);

  useEffect(() => {
    axios
      .get(
        `/api/blog-search?s=${s || ""}&c=${c || ""}&t=${t || ""}&p=${p || 1}`
      )
      .then((r) => {
        setTotalPages(r.data.totalPages);
        setFilteredBlogPosts(r.data.posts);
        setTagsArray(r.data.tags);
        setCategoriesArray(r.data.categories);
      });
  }, [s, c, t, p]);

  useEffect(() => {
    setSearchTerm(s || "");
  }, [s]);

  useEffect(() => {
    setSelectedCategory(c || "");
  }, [c]);

  useEffect(() => {
    setSelectedTag(t || "");
  }, [t]);

  useEffect(() => {
    if (categoriesArray.length) {
      setSelectedCategory(c || "");
    }
  }, [c, categoriesArray.length]);

  useEffect(() => {
    if (tagsArray.length) {
      setSelectedTag(t || "");
    }
  }, [t, tagsArray.length]);

  const getActivatedFilterTypesCount = () => {
    let activatedFilterTypesCount = 0;
    if (t) {
      activatedFilterTypesCount = activatedFilterTypesCount + 1;
    }
    if (c) {
      activatedFilterTypesCount = activatedFilterTypesCount + 1;
    }
    if (s) {
      activatedFilterTypesCount = activatedFilterTypesCount + 1;
    }
    return activatedFilterTypesCount;
  };

  const getSeoData = () => {
    const isFirstPage = !p || p === "1";
    const selectedCategoryTitle = c
      ? categoriesArray.find(
          ({ slugFriendlyName }) => slugFriendlyName === selectedCategory
        )?.title || ""
      : "";
    const selectedTagTitle = t
      ? tagsArray.find(
          ({ slugFriendlyName }) => slugFriendlyName === selectedTag
        )?.title || ""
      : "";

    if (isFirstPage && (!!selectedCategoryTitle || !!selectedTagTitle)) {
      const categoryTitle = `${
        selectedCategoryTitle ? ` ${selectedCategoryTitle}` : ""
      }`;
      const tagTitle = `${selectedTagTitle ? `on ${selectedTagTitle}` : ``}`;
      return {
        title: `Latest${categoryTitle} resources ${tagTitle} from Rubric | rubric.com`,
        description: `At Rubric, we're constantly on the cutting edge of translation & localization. Keep yourself up to date with our ${selectedTagTitle}${
          selectedTagTitle && selectedCategoryTitle ? "/" : ""
        }${selectedCategoryTitle} resources`,
      };
    } else {
      let defaultSeoTitle = "Blog, E-Books & Case Studies";
      if (!!selectedTagTitle) {
        defaultSeoTitle = selectedTagTitle;
      } else if (!!c) {
        defaultSeoTitle = selectedCategoryTitle
          .replace("study", "Studies")
          .replace("ebook", "E-Books")
          .replace("post", "Posts");
      }
      return {
        title: `Translation Resources | ${defaultSeoTitle} | Rubric`,
        description: `At Rubric, we're constantly on the cutting edge of translation & localization. Keep yourself up to date with our ${defaultSeoTitle} resources`,
      };
    }
  };

  const { title: seoTitle, description: seoDescription } = getSeoData();

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={data.datoCmsBlog.headerBackgroundImage.url}
        url={pageContext.url}
        type="blog"
        breadcrumbTrails={[[{ name: "Resources" }]]}
      />
      {getActivatedFilterTypesCount() > 1 && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <Section
        backgroundImage={data.datoCmsBlog.headerBackgroundImage.gatsbyImageData}
        backgroundOverlay="Red"
      >
        <Grid>
          <div>
            <div>RESOURCES</div>
            <h2>Improve your own localization with our expert resources</h2>
          </div>
          <div>
            <p>
              We are committed to helping you improve your localization
              processes, whether you work directly with us or not.
            </p>
            <p>
              At Rubric, we have always been at the cutting edge of translation
              and localization. Our expert resources keep you up to date with
              the latest in localization techniques, tools, and trends.
            </p>
            <p>
              With our blog articles, ebooks, videos, and more, you can raise
              your global content to the next level.
            </p>
          </div>
        </Grid>
      </Section>
      <FilterBar>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            navigate(
              `/en-us/blog?s=${encodeURIComponent(
                searchTerm
              )}&c=${selectedCategory}&p=1&t=${selectedTag}`
            );
          }}
        >
          <input
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            type="search"
            placeholder="Search..."
          />
        </form>
        <div>
          <select
            value={selectedCategory || ""}
            onChange={(e) => {
              navigate(
                `/en-us/blog?s=${encodeURIComponent(searchTerm)}&c=${
                  e.target.value
                }&p=1&t=`
              );
            }}
          >
            {selectedCategory !== null &&
              categoriesArray.map((category) => (
                <option key={category.id} value={category.slugFriendlyName}>
                  {category.title}{" "}
                  {!category.hideCount && `(${category.count})`}
                </option>
              ))}
          </select>
        </div>
        <div>
          <select
            value={selectedTag || ""}
            onChange={(e) => {
              navigate(
                `/en-us/blog?s=${encodeURIComponent(
                  searchTerm
                )}&c=${selectedCategory}&p=1&t=${e.target.value}`
              );
            }}
          >
            {selectedTag !== null &&
              tagsArray.map((tag) => (
                <option key={tag.id} value={tag.slugFriendlyName}>
                  {tag.title} {!tag.hideCount && `(${tag.count})`}
                </option>
              ))}
          </select>
        </div>
      </FilterBar>
      <Section>
        <SubPagesGrid
          pages={filteredBlogPosts.map((post) => ({
            id: post.id,
            title: post.title,
            description: post.seo.description,
            image: post.seo.image?.responsiveImage?.src,
            slug: post.slug,
            subtitle: post.category.title.toUpperCase(),
          }))}
        />
        <Pagination
          totalPages={totalPages}
          activePage={currentPage}
          pageButtonDestination={(i) =>
            `/blog?s=${encodeURIComponent(
              searchTerm
            )}&c=${selectedCategory}&p=${i + 1}&t=${selectedTag}`
          }
        />
      </Section>
      <SocialMediaLinksFooter />
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery {
    datoCmsBlog {
      headerBackgroundImage {
        url
        gatsbyImageData
      }
    }
    allDatoCmsPostCategory {
      nodes {
        originalId
        slugFriendlyName
      }
    }
    allDatoCmsPostTag {
      nodes {
        originalId
        slugFriendlyName
      }
    }
  }
`;

export default Blog;
